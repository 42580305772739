import React, { useState } from "react";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
// import './3rdEye.css';
import cloudResourceImage1 from "../Assets/images/3rdeye1.jpg";
import cloudResourceImage2 from "../Assets/images/cloud2.jpg";
import cloudResourceImage3 from "../Assets/images/cloud3.jpg";
import Navbar from "react-bootstrap/Navbar";
import image1 from "../Assets/images/3rd-eye5.jpg";
import image2 from "../Assets/images/3rd-eye6.jpg";
import image3 from "../Assets/images/3rd-eye7.jpg";
import image4 from "../Assets/images/3rd-eye8.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
  faLeaf,
  faChartLine,
  faCloudUploadAlt,
  faMicrochip,
  faShieldAlt,
  faChartBar,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faAws } from "@fortawesome/free-brands-svg-icons";

// ... imports remain the same ...

const ThirdEyePage = () => {
  const centerAlignedContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#fff",
  };
  // ... existing state and style definitions ...

  return (
    <div style={centerAlignedContainer}>
      {/* Hero Section with Full-width Image and Yellow Overlay */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "500px",
          minHeight: "900px",
        }}
      >
        <img
          src={image1}
          alt="Banner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "fill",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "linear-gradient(180deg, transparent 0%, #0C1A4A 100%)",
            opacity: 0.7,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: "100%",
            padding: "0 20px",
          }}
        >
          <h1
            style={{
              color: "#0C1A4A",
              fontSize: "3rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: "20px",
            }}
          >
            Automated Crop Classification
            <br />
          </h1>
          <p style={{ color: "white" }}>
            Instantly classify your agriculture produce using advance machine
            learning Technology
          </p>
          <button
            className="btn"
            style={{
              backgroundColor: "#0C1A4A",
              color: "white",
              padding: "10px 30px",
              borderRadius: "25px",
              border: "none",
            }}
            onClick={() =>
              (window.location.href =
                "https://3rdeye.dhruthzucitech.solutions/Login")
            }
          >
            Start Classifying
          </button>
        </div>
      </div>

      {/* Three Card Section */}
      <Container
        style={{ marginTop: "-50px", position: "relative", zIndex: 2 }}
      >
        <Row>
          <Col md={4}>
            <Card
              style={{
                border: "none",
                borderRadius: "15px",
                overflow: "hidden",
                marginBottom: "20px",
                backgroundColor: "#0C1A4A",
              }}
            >
              <Card.Body
                style={{ padding: "20px", textAlign: "center", color: "white" }}
              >
                <FontAwesomeIcon
                  icon={faCloudUploadAlt}
                  style={{ fontSize: "3rem", marginBottom: "15px" }}
                />
                <Card.Title>Easy Image Upload</Card.Title>
                <Card.Text style={{ marginBottom: "15px",color:"white" }}>
                  Simply upload photos of your agricultural produce for instant
                  classification
                </Card.Text>
                {/* <button
                  className="btn btn-light"
                  style={{ borderRadius: "25px" }}
                >
                  Try Now
                </button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              style={{
                border: "none",
                borderRadius: "15px",
                overflow: "hidden",
                marginBottom: "20px",
                backgroundColor: "#0C1A4A",
              }}
            >
              <Card.Body
                style={{ padding: "20px", textAlign: "center", color: "white" }}
              >
                <FontAwesomeIcon
                  icon={faRobot}
                  style={{ fontSize: "3rem", marginBottom: "15px" }}
                />
                <Card.Title>AI-Powered Analysis</Card.Title>
                <Card.Text style={{ marginBottom: "15px",color: "white"}}>
                  Advanced machine learning for accurate crop classification and
                  quality assessment
                </Card.Text>
                {/* <button
                  className="btn btn-light"
                  style={{ borderRadius: "25px" }}
                >
                  Learn More
                </button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              style={{
                border: "none",
                borderRadius: "15px",
                overflow: "hidden",
                marginBottom: "20px",
                backgroundColor: "#0C1A4A",
              }}
            >
              <Card.Body
                style={{ padding: "20px", textAlign: "center", color: "white" }}
              >
                <FontAwesomeIcon
                  icon={faChartLine}
                  style={{ fontSize: "3rem", marginBottom: "15px" }}
                />
                <Card.Title>Optimize Yields</Card.Title>
                <Card.Text style={{ marginBottom: "15px",color: "white" }}>
                  Make data-driven decisions to improve crop quality and
                  resource utilization
                </Card.Text>
                {/* <button
                  className="btn btn-light"
                  style={{ borderRadius: "25px" }}
                >
                  Get Started
                </button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Products Section */}
      <Container style={{ marginTop: "30px" }}>
        {/* <Row>
          <Col md={6}>
            <h2 style={{ fontSize: "2.5rem", marginBottom: "20px" }}>
              Providing The Finest Products
              <br />
              To The Best Feed Suppliers.
            </h2>
            <p style={{ color: "#666", marginBottom: "20px" }}>
              Detailed description about your agricultural products and
              services...
            </p>
            <button className="btn btn-dark" style={{ borderRadius: "25px" }}>
              Discover More
            </button>
          </Col>
          <Col md={6}>
            <Card
              style={{
                border: "none",
                borderRadius: "15px",
                backgroundColor: "#4CAF50",
                color: "white",
                padding: "30px",
              }}
            >
              <h3>12,980+</h3>
              <p>Success Stories</p>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li>✓ Modern Agricultural Equipment</li>
                <li>✓ Innovative Solutions</li>
                <li>✓ Expert Guidance</li>
              </ul>
            </Card>
          </Col>
        </Row> */}

        <Container
          fluid
          style={{
            backgroundColor: "#0C1A4A",
            padding: "50px 0",
            marginTop: "50px",
            width: "auto",
            borderRadius: "20px",
          }}
        >
          <Container>
            
            <Row>
              {/* Main Content Row */}
              <Row className="align-items-center">
                {/* Left Column */}
                <Col md={6} className="mb-4">
                
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "30px",
                    }}
                  >
                    {/* Circular Image */}
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        flexShrink: 0,
                      }}
                    >
                      <img
                        src={cloudResourceImage1}
                        alt="AI Crop Classification"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fill",
                        }}
                      />
                    </div>

                    <div style={{ color: "white" }}>
                      <h2
                        style={{
                          fontSize: "2.5rem",
                          marginBottom: "20px",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        Intelligent Crop
                        <br />
                        Classification
                      </h2>
                      <p style={{ marginBottom: "20px", color: "white" }}>
                        Upload images of your agricultural produce and let our
                        AI-powered system provide instant, accurate
                        classification of crop types and varieties.
                      </p>
                      <button
                        className="btn"
                        style={{
                          backgroundColor: "white",
                          color: "#0C1A4A",
                          padding: "10px 30px",
                          borderRadius: "25px",
                          border: "none",
                        }}
                        onClick={() =>
                          (window.location.href =
                            "https://3rdeye.dhruthzucitech.solutions/Login")
                        }
                      >
                        Try Classification →
                      </button>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <Row>
                    {/* Mission Statement Card */}
                    <Col xs={12} className="mb-4">
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "30px",
                          borderRadius: "15px",
                        }}
                      >
                        <h3 style={{ color: "#0C1A4A", marginBottom: "15px" }}>
                          How 3rdEye Works
                        </h3>
                        <p style={{ color: "#0C1A4A" }}>
                          Simply upload an image of your produce, select the
                          crop category, and our advanced machine learning
                          algorithms will analyze and classify your crops in
                          real-time. Powered by AWS technologies, 3rdEye
                          delivers precise results to optimize your agricultural
                          decision-making.
                        </p>
                      </div>
                    </Col>
                    {/* Image Card */}
                    <Col xs={12}>
                      <div
                        style={{
                          borderRadius: "15px",
                          overflow: "hidden",
                          height: "250px",
                        }}
                      >
                        <img
                          src={image4}
                          alt="AI-powered crop classification"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "fill",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Container>
        </Container>
        {/* products section */}
        <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
        <h2
                style={{
                  textAlign: "center",
                  color: "#0C1A4A",
                  marginBottom: "30px",
                }}
              >
                Our Services
              </h2>
          <Row>
            <Col md={4}>
            
              <Card
                style={{
                  border: "none",
                  borderRadius: "15px",
                  overflow: "hidden",
                  marginBottom: "20px",
                }}
              >
                <Card.Img
                  variant="top"
                  src={cloudResourceImage1}
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body style={{ padding: "20px" }}>
                  <Card.Title>Real-Time Classification</Card.Title>
                  <Card.Text>
                    Advanced machine learning algorithms provide instant,
                    accurate classification of crop types, classes, and
                    varieties through simple image uploads.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                style={{
                  border: "none",
                  borderRadius: "15px",
                  overflow: "hidden",
                  marginBottom: "20px",
                }}
              >
                <Card.Img
                  variant="top"
                  src={image3}
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body style={{ padding: "20px" }}>
                  <Card.Title>AWS-Powered Platform</Card.Title>
                  <Card.Text>
                    Leveraging AWS technologies for robust, scalable performance
                    and reliable crop classification results you can trust.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                style={{
                  border: "none",
                  borderRadius: "15px",
                  overflow: "hidden",
                  marginBottom: "20px",
                }}
              >
                <Card.Img
                  variant="top"
                  src={image2}
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body style={{ padding: "20px" }}>
                  <Card.Title>Optimize Your Yield</Card.Title>
                  <Card.Text>
                    Make data-driven decisions to improve crop quality, increase
                    yields, and achieve better resource utilization in your
                    farming practices.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* for features  */}
        <Container>
          <Row className="mt-5">
            <Col md={12}>
              <h2
                style={{
                  textAlign: "center",
                  color: "#0C1A4A",
                  marginBottom: "30px",
                }}
              >
                Explore Our Features
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <Card
                className="border-0"
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "15px",
                  // boxShadow: "0 4px 20px rgba(0, 123, 255, 0.5)",
                }}
              >
                <Card.Body style={{ height: "250px" }}>
                  <FontAwesomeIcon
                    icon={faCloudUploadAlt}
                    style={{
                      fontSize: "2rem",
                      color: "#007bff",
                      marginBottom: "1rem",
                    }}
                  />
                  <Card.Title style={{ fontWeight: "bold", color: "#343a40" }}>
                    Easy Image Upload
                  </Card.Title>
                  <Card.Text>
                    Simply upload images of your agricultural produce through
                    our intuitive interface. Our platform accepts various image
                    formats and provides instant processing for quick
                    classification results.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card
                className="border-0"
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "15px",
                  // boxShadow: "0 4px 20px rgba(0, 123, 255, 0.5)",
                }}
              >
                <Card.Body style={{ height: "250px" }}>
                  <FontAwesomeIcon
                    icon={faMicrochip}
                    style={{
                      fontSize: "2rem",
                      color: "#007bff",
                      marginBottom: "1rem",
                    }}
                  />
                  <Card.Title style={{ fontWeight: "bold", color: "#343a40" }}>
                    Advanced ML Classification
                  </Card.Title>
                  <Card.Text>
                    Powered by state-of-the-art machine learning algorithms, our
                    system accurately identifies and classifies various crop
                    types, varieties, and quality grades in real-time.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card
                className="border-0"
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "15px",
                  // boxShadow: "0 4px 20px rgba(0, 123, 255, 0.5)",
                }}
              >
                <Card.Body style={{ height: "250px" }}>
                  <FontAwesomeIcon
                    icon={faAws}
                    style={{
                      fontSize: "2rem",
                      color: "#007bff",
                      marginBottom: "1rem",
                    }}
                  />
                  <Card.Title style={{ fontWeight: "bold", color: "#343a40" }}>
                    AWS-Powered Infrastructure
                  </Card.Title>
                  <Card.Text>
                    Built on robust AWS infrastructure, ensuring high
                    availability, scalability, and reliable performance for all
                    your crop classification needs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <Card
                className="border-0"
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "15px",
                  // boxShadow: "0 4px 20px rgba(0, 123, 255, 0.5)",
                }}
              >
                <Card.Body style={{ height: "250px" }}>
                  <FontAwesomeIcon
                    icon={faShieldAlt}
                    style={{
                      fontSize: "2rem",
                      color: "#007bff",
                      marginBottom: "1rem",
                    }}
                  />
                  <Card.Title style={{ fontWeight: "bold", color: "#343a40" }}>
                    Secure Analysis
                  </Card.Title>
                  <Card.Text>
                    Your data security is our priority. All uploads and analyses
                    are processed through secure channels with enterprise-grade
                    encryption and protection.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card
                className="border-0"
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "15px",
                  // boxShadow: "0 4px 20px rgba(0, 123, 255, 0.5)",
                }}
              >
                <Card.Body style={{ height: "250px" }}>
                  <FontAwesomeIcon
                    icon={faChartBar}
                    style={{
                      fontSize: "2rem",
                      color: "#007bff",
                      marginBottom: "1rem",
                    }}
                  />
                  <Card.Title style={{ fontWeight: "bold", color: "#343a40" }}>
                    Detailed Analytics
                  </Card.Title>
                  <Card.Text>
                    Get comprehensive insights into your crop quality, variety
                    distribution, and classification trends. Make data-driven
                    decisions to optimize your agricultural practices.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card
                className="border-0"
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "15px",
                  // boxShadow: "0 4px 20px rgba(0, 123, 255, 0.5)",
                }}
              >
                <Card.Body style={{ height: "250px" }}>
                  <FontAwesomeIcon
                    icon={faSyncAlt}
                    style={{
                      fontSize: "2rem",
                      color: "#007bff",
                      marginBottom: "1rem",
                    }}
                  />
                  <Card.Title style={{ fontWeight: "bold", color: "#343a40" }}>
                    Real-Time Processing
                  </Card.Title>
                  <Card.Text>
                    Experience instant classification results with our optimized
                    processing pipeline. Perfect for high-volume classification
                    needs and time-sensitive decisions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ThirdEyePage;
