import React from 'react';
import { Container, Button, Row, Col, Card, Image } from 'react-bootstrap';
import image1 from "../../Assets/images/icons/icons.png";
import image2 from "../../Assets/images/icons/icon2.png";
import image3 from "../../Assets/images/icons/icon3.png";
import image4 from "../../Assets/images/icons/icons4.png";
import bgImage from "../../Assets/BannerImage4.png";
import image5 from "../../Assets/imagecard.png";

const benefitCards = [
  {
    image: image1,
    title: "Agricultural Researchers",
    description: "SeedzHub provides AI-driven tools for seed characterization, predictive modeling, and real-time data analysis."
  },
  {
    image: image2,
    title: "Small-Scale Farmers",
    description: "Empowering farmers with accessible technology, local language support, and community insights."
  },
  {
    image: image3,
    title: "Large-Scale Enterprises",
    description: "Scalable tools for managing data and monitoring seed-to-sapling processes."
  },
  {
    image: image4,
    title: "Agricultural Organizations",
    description: "Tools and data for sustainability initiatives and impactful community outreach."
  }
];

const Seedzhub = () => {
  const handleGetStarted = () => {
    window.open('https://portal.seedzhub.com/login', '_blank');
  };

  const cardHoverStyle = {
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = 'scale(1.05)';
    e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.2)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = 'scale(1)';
    e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  };

  return (
    <Container fluid className="p-0">
      <div className="landing-page">
        {/* Hero Section */}
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{
            height: '700px',
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
          }}
        >
          <Button
            variant="light"
            size="lg"
            style={{ backgroundColor: 'rgb(12, 26, 74)', color: '#fff' }}
            onClick={handleGetStarted}
          >
            Get Started
          </Button>
        </div>

        {/* Features Section */}
        <Container>
          <h2 className="text-center my-4">
            Who <span className="highlight">Benefits</span> from SeedzHub?
          </h2>
          <Row className="justify-content-center">
            {benefitCards.map((card, index) => (
              <Col xs={12} sm={6} md={3} className="mb-4" key={index}>
                <Card
                  className="h-100 shadow-lg text-center"
                  style={cardHoverStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Card.Body>
                    <Image
                      src={card.image}
                      alt={card.title}
                      className="mx-auto mt-3"
                      style={{ width: '80px', height: '80px' }}
                      fluid
                    />
                    <Card.Title className="mt-3">{card.title}</Card.Title>
                    <Card.Text>{card.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>

        {/* Additional Section */}
        <Container className="py-2">
          <Row className="align-items-center justify-content-center">
            <Col xs={12} sm={10} md={6} className="text-center mb-4 mb-md-0">
              <Image
                src={image5}
                alt="Additional Image"
                className="rounded shadow-lg"
                fluid
              />
            </Col>
            <Col xs={12} sm={10} md={6} className="pe-md-5">
              <h2 className="fw-bold mb-4 text-dark display-5 text-center text-md-start">
                Transforming the Future of Agriculture With AI and Community-Driven Insights
              </h2>
              <p className="lead mb-4 text-muted text-center text-md-start">
                SeedzHub is a cutting-edge platform designed to revolutionize the seed-to-sapling journey, blending advanced AI technology with global collaboration. Whether you are a researcher, farmer, or part of an agricultural organization, SeedzHub empowers you to make data-driven decisions, enhance seed productivity, and support sustainable agricultural practices.
              </p>
            </Col>
          </Row>
        </Container>

        {/* Mission & Vision Section */}
        <Container className="py-2">
          <Row className="justify-content-center text-center">
            <Col xs={12} sm={10} md={8}>
              <h1 className="fw-bold mb-4">Our Mission & Vision</h1>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {[
              {
                title: "Our Mission",
                text: "SeedzHub is dedicated to advancing agricultural productivity and sustainability by providing a digital ecosystem where data, technology, and community work hand-in-hand. Our mission is aligned with the United Nations Sustainable Development Goals (SDGs), specifically No Poverty and No Hunger. By supporting resilient crop growth and empowering farmers, SeedzHub fosters sustainable practices that uplift communities."
              },
              {
                title: "Our Vision",
                text: "At SeedzHub, we envision a world where sustainable agricultural practices empower communities and secure the future of food production. By advancing seed-to-sapling innovations, we aim to create a resilient agricultural landscape that promotes food security and supports a fair, thriving world."
              }
            ].map((item, index) => (
              <Col xs={12} sm={6} md={4} className="mb-4" key={index}>
                <Card
                  className="shadow-lg h-100"
                  style={cardHoverStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Card.Body>
                    <Card.Title className="fw-bold">{item.title}</Card.Title>
                    <Card.Text>{item.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default Seedzhub;