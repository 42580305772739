import React from "react";
import { Container, Row, Col, Button, Dropdown, Image } from "react-bootstrap";
import "animate.css";
import banner from "../../Assets/images/BTS4.jpg";
import btsLogo from "../../Assets/images/btslogo.png";
import icrisatLogo from "../../Assets/images/icrisatLogo.jpg";
import christmasImage from "../../Assets/images/christmas.jpg";
import footerImage from "../../Assets/images/footer.png";
import bts from "../../Assets/images/BTS9.jpg";

const EventOrganizer = () => {
  return (
    <>
      {/* Banner Section */}
      <div
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          textShadow: "2px 2px 5px black",
          borderBottomLeftRadius: "25px",
          borderBottomRightRadius: "25px",
        }}
      >
        <div className="text-center px-3">
          <h1 className="display-3 fw-bold animate__animated animate__fadeIn">
            TECH EVENTS
          </h1>
          <p
            className="fs-4 animate__animated animate__fadeIn animate__delay-1s"
            style={{ color: "white" }}
          >
            Where memories begin
          </p>
        </div>
      </div>

      {/* Filters Section */}
      <Container className="my-5 animate__animated animate__slideInUp">
        <Row className="justify-content-center mb-4">
          {["Weekdays", "Event Type", "Any Category"].map((filter, index) => (
            <Col xs={12} sm={6} md={4} className="mb-3" key={index}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  className="w-100"
                  style={{
                    backgroundColor: "#f4f4ff",
                    borderRadius: "50px",
                    color: "#2b2a72",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  {filter}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {filter === "Weekdays" && (
                    <>
                      <Dropdown.Item>Monday</Dropdown.Item>
                      <Dropdown.Item>Tuesday</Dropdown.Item>
                      <Dropdown.Item>Wednesday</Dropdown.Item>
                    </>
                  )}
                  {filter === "Event Type" && (
                    <>
                      <Dropdown.Item>Conference</Dropdown.Item>
                      <Dropdown.Item>Workshop</Dropdown.Item>
                      <Dropdown.Item>Seminar</Dropdown.Item>
                    </>
                  )}
                  {filter === "Any Category" && (
                    <>
                      <Dropdown.Item>Technology</Dropdown.Item>
                      <Dropdown.Item>Education</Dropdown.Item>
                      <Dropdown.Item>Health</Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          ))}
        </Row>

        {/* Events Section */}
        <h2 className="text-center mb-4 animate__animated animate__fadeIn">
          EVENTS
        </h2>
        <Row className="event-cards">
          {[
            {
              img: btsLogo,
              date: "NOV 19",
              title: "Bengaluru Tech Summit",
              description: "Uniting Innovation, Inspiring Futures.",
            },
            {
              img: icrisatLogo,
              date: "AUG 20",
              title: "ICRISAT Conference",
              description: "Nurturing Dry lands, Transforming Lives.",
            },
            {
              img: christmasImage,
              date: "DEC 25",
              title: "Christmas Celebration",
              description: "Spreading Joy and Hope this Christmas!",
            },
          ].map((event, index) => (
            <Col xs={12} sm={6} md={4} className="mb-4" key={index}>
              <div
                className="event-card text-center p-3 animate__animated animate__zoomIn h-100"
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.02)"}
                onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
              >
                <Image
                  src={event.img}
                  alt={event.title}
                  fluid
                  className="mb-2"
                  style={{
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                />
                <p className="text-purple fw-bold fs-5 mb-0">
                  {event.date}
                </p>
                <h3 className="event-title" style={{ fontSize: "18px" }}>
                  {event.title}
                </h3>
                <p className="event-description" style={{ fontSize: "14px" }}>
                  {event.description}
                </p>
              </div>
            </Col>
          ))}
        </Row>

        {/* Load More Button */}
        <div className="text-center mt-5 animate__animated animate__bounce">
          <Button variant="outline-primary" style={{ borderRadius: "20px" }}>
            Load More
          </Button>
        </div>

        {/* highlights section */}
        {/* <div className="my-5 py-5" style={{ backgroundColor: "white", borderRadius: '20px',marginBottom:'20px' }}>
          <h2 className="text-center mb-5 animate__animated animate__fadeIn">Our Highlights</h2> */}
          
          {/* Highlight 1 */}
          {/* <Row className="align-items-center mb-5" style={{width:'100%', margin: '0'}}>
            <Col md={6} className="animate__animated animate__fadeInLeft" style={{paddingLeft: '0'}}>
              <div className="p-4">
                <h3 className="mb-4">
                  Your Partner In Achieving Growth Through Digital
                  Transformation
                </h3>
                <p className="text-muted">
                  We support businesses in effectively managing digital
                  transformation initiatives and achieving tangible growth by
                  harnessing the collective strength of our experience and
                  extensive network of expertise.
                </p>
              </div>
            </Col>
            <Col md={6} className="animate__animated animate__fadeInRight">
              <div className="p-4 text-center">
                <img
                  src={bts}
                  alt="Digital Transformation"
                  style={{
                    width: "100%",
                    maxWidth: "800px",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </div>
            </Col>
          </Row> */}

          {/* Highlight 2 */}
          {/* <Row className="align-items-center mb-5" style={{width:'100%', margin: '0'}}>
            <Col md={6} className="animate__animated animate__fadeInLeft order-md-2">
              <div className="p-4">
                <h3 className="mb-4">
                  Industry 4.0 Redefined
                </h3>
                <p className="text-muted">
                  ThirdEye AI is a leading technology company focused on delivering
                  cutting-edge solutions and services that empower businesses to prosper
                  in the digital era of Industry 4.0.
                </p>
              </div>
            </Col>
            <Col md={6} className="animate__animated animate__fadeInRight order-md-1">
              <div className="p-4 text-center">
                <img
                  src={bts}
                  alt="Industry 4.0"
                  style={{
                    width: "100%",
                    maxWidth: "800px",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </div>
            </Col>
          </Row> */}

          {/* Highlight 3 */}
          {/* <Row className="align-items-center" style={{width:'100%', margin: '0'}}>
            <Col md={6} className="animate__animated animate__fadeInLeft">
              <div className="p-4">
                <h3 className="mb-4">
                  Data-Driven Solutions
                </h3>
                <p className="text-muted">
                  Leverage the power of advanced analytics and machine learning to transform
                  your raw data into actionable insights. Our solutions help you make
                  informed decisions and stay ahead of the competition.
                </p>
              </div>
            </Col>
            <Col md={6} className="animate__animated animate__fadeInRight">
              <div className="p-4 text-center">
                <img
                  src={bts}
                  alt="Data Analytics"
                  style={{
                    width: "100%",
                    maxWidth: "800px",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row 
            className="align-items-center" 
            style={{
              width:'100%', 
              margin: '0',
              '@media (max-width: 500px)': {
                marginBottom: '600px'
              }
            }}
          ></Row>
        </div> */}
      </Container>
      {/* Create Event Section */}
      <div
        className="text-center py-5 animate__animated animate__fadeIn"
        style={{
          backgroundColor: "#e0e0ff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          maxHeight: "408px",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          // marginTop:'600px',
          position:'relative',
        }}
      >
        <img
          src={footerImage}
          alt="Footer Illustration"
          style={{
            width: "100%",
            maxWidth: "650px",
            height: "auto",
          }}
        />
        {/* <div style={{ textAlign: "center" }}>
          <h3
            className="fw-bold"
            style={{
              marginBottom: "15px",
              fontSize: "24px",
              color: "#2b2a72",
            }}
          >
            Make your own Event
          </h3>
          <Button
            variant="primary"
            style={{
              backgroundColor: "#d5006d",
              border: "none",
              padding: "10px 30px",
              fontSize: "16px",
              borderRadius: "50px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Create Events
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default EventOrganizer;