// import React from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import "./jobRole.css"; // Create this CSS file to add custom styles
// import { useLocation, useNavigate } from "react-router-dom";
// import { DURATION, WHATWEOFFER } from "../../Config/JDconfig";

// const JobRolePage = () => {
//   const location = useLocation();
//   const {
//     role,
//     JobOverview,
//     RolesAndResponsibilities ,
//     SkillsAndQualifications ,
//     EmploymentType,
//   } = location.state || {};

//   const handleLoginClick = () => {

//     localStorage.setItem("jobTitle", role || ""); // Dynamically store the title
//     window.location.href =
//     //  "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://prathik.d2m2wtcivnya67.amplifyapp.com/CareerForm";
//      "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/CareerForm";
//   };

//   return (
//     <Container style={{ marginTop: "120px" }}>
//       <div
//         className="section-title"
//         data-aos="fade-up"
//         style={{
//           textAlign: "center",
//           padding: "10px",
//           borderRadius: "20px",
//         }}
//       >
//         <h1
//           style={{
//             fontFamily: "impact",
//             margin: "0",
//           }}
//         >
//           {role || "Job Role"}
//         </h1>
//       </div>

//       <Row className="my-4">
//         <Col>
//           <h5>Employment Type:</h5>
//           <p>{EmploymentType || "No description available."}</p>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Job Overview:</h5>
//           <p>{JobOverview || "No description available."}</p>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Roles and Responsibilities:</h5>
//           <ul>
//             {Array.isArray(RolesAndResponsibilities) &&
//             RolesAndResponsibilities.length > 0 ? (
//               RolesAndResponsibilities.map((responsibility, index) => (
//                 <li key={index}>{responsibility}</li>
//               ))
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Skills And Qualifications:</h5>
//           <ul>
//             {Array.isArray(SkillsAndQualifications) &&
//             SkillsAndQualifications.length > 0 ? (
//               SkillsAndQualifications.map((SkillsAndQualifications, index) => (
//                 <li key={index}>{SkillsAndQualifications}</li>
//               ))
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>

//       <Row className="my-4">
//         <Col>
//           <h5>What we offer:</h5>
//           <ul>
//             {Array.isArray(WHATWEOFFER) &&
//             WHATWEOFFER.length > 0 ? (
//               WHATWEOFFER.map((WHATWEOFFER, index) => (
//                 <li key={index}>{WHATWEOFFER}</li>
//               ))
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//           {/* <p>{WHATWEOFFER || "No description available."}</p> */}
//         </Col>
//       </Row>

//       {/* Conditionally render the "Duration and Compensation" section */}
//       {role !== "Cloud Engineer" || "Program/Product Manager" && (
//         <Row className="my-4">
//           <Col>
//             <h5>Duration and Compensation:</h5>
//             <p>{DURATION || "No description available."}</p>
//           </Col>
//         </Row>
//       )}

//       <Button
//         variant="dark"
//         className="apply-now-button"
//         onClick={handleLoginClick}
//         style={{ marginBottom: "40px" }}
//       >
//         Apply Now <span className="arrow">→</span>
//       </Button>
//     </Container>
//   );
// };

// export default JobRolePage;

// import React from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import "./jobRole.css"; // Add custom styles here
// import { useLocation, useNavigate } from "react-router-dom";
// import { DURATION, WHATWEOFFER } from "../../Config/JDconfig";

// const JobRolePage = () => {
//   const location = useLocation();
//   const {
//     role = "Job Role", // Fallback values if data is missing
//     JobOverview = "No job overview available.",
//     RolesAndResponsibilities = [],
//     SkillsAndQualifications = [],
//     EmploymentType = "Not Specified",
//     WhatWeOffer,
//   } = location.state || {}; // Retrieve state from previous page or default to empty object

//   const handleLoginClick = () => {
//     localStorage.setItem("jobTitle", role); // Store the role in localStorage
//     window.location.href =
//        "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://www.dhruthzucitech.solutions/CareerForm";
//       // "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/CareerForm";
//   };

//   return (
//     <Container style={{ marginTop: "120px" }}>
//       <div
//         className="section-title"
//         data-aos="fade-up"
//         style={{
//           textAlign: "center",
//           padding: "10px",
//           borderRadius: "20px",
//         }}
//       >
//         <h1
//           style={{
//             fontFamily: "impact",
//             margin: "0",
//           }}
//         >
//           {role}
//         </h1>
//       </div>

//       <Row className="my-4">
//         <Col>
//           <h5>Employment Type:</h5>
//           <p>{EmploymentType}</p>
//         </Col>
//       </Row>

//       <Row className="my-4">
//         <Col>
//           <h5>Job Overview:</h5>
//           <p>{JobOverview}</p>
//         </Col>
//       </Row>

//       <Row className="my-4">
//         <Col>
//           <h5>Roles and Responsibilities:</h5>
//           <ul>
//             {RolesAndResponsibilities &&
//             typeof RolesAndResponsibilities === "string" ? (
//               RolesAndResponsibilities.split("\n").map(
//                 (responsibility, index) => <li key={index}>{responsibility}</li>
//               )
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>

//       <Row className="my-4">
//         <Col>
//           <h5>Skills and Qualifications:</h5>
//           <ul>
//             {SkillsAndQualifications &&
//             typeof SkillsAndQualifications === "string" ? (
//               SkillsAndQualifications.split("\n").map((skill, index) => (
//                 <li key={index}>{skill}</li>
//               ))
//             ) : (
//               <li>No skills or qualifications available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>

//       <Row className="my-4">
//         <Col>
//           <h5>What We Offer:</h5>
//           <ul>
//             {WhatWeOffer && typeof WhatWeOffer === "string" ? (
//               WhatWeOffer.split("\n").map((offer, index) => (
//                 <li key={index}>{offer}</li>
//               ))
//             ) : (
//               <li>No offers available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>

//       {/* Conditionally Render Duration and Compensation */}
//       {/* {(role !== "Cloud Engineer" && role !== "Program/Product Manager") && (
//         <Row className="my-4">
//           <Col>
//             <h5>Duration and Compensation:</h5>
//             <p>{DURATION || "No description available."}</p>
//           </Col>
//         </Row>
//       )} */}

//       <Button
//         variant="dark"
//         className="apply-now-button"
//         onClick={handleLoginClick}
//         style={{ marginBottom: "40px" }}
//       >
//         Apply Now <span className="arrow">→</span>
//       </Button>
//     </Container>
//   );
// };

// export default JobRolePage;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';

const JobRolePage = () => {
  const { jobId } = useParams(); // Extract jobId from URL
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Log the raw jobId from URL
    console.log('Extracted jobId from URL:', jobId);

    // Ensure jobId is parsed to a number
    const parsedJobId = parseInt(jobId, 10);
    console.log('Parsed jobId:', parsedJobId); // Log the parsed value

    // If the jobId is invalid, show an error and stop further execution
    if (isNaN(parsedJobId)) {
      setError('Invalid Job ID');
      setLoading(false);
      return;
    }

    const fetchJobDetails = async () => {
      try {
        const response = await fetch("https://938m9p4nqk.execute-api.ap-south-1.amazonaws.com/prod/get?type=get", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "abTLSGqgK19iPy8i5kI0Y3DK9vgoAVoD4qiK6nmw",
          },
        });
    
        if (!response.ok) {
          throw new Error("Failed to fetch job details");
        }
    
        const data = await response.json();
        console.log('API Response:', data); // Log the entire response
    
        // Extract body-json from the response
        const jobData = data['body-json'];  // Assuming 'body-json' contains the actual job data
        console.log('Raw Job Data:', jobData);  // Log the raw job data
    
        // The body is a string, so we need to parse it
        const parsedJobData = JSON.parse(jobData.body);  // Parse the string to get the job details
        console.log('Parsed Job Data:', parsedJobData); // Log the parsed job data
    
        // Find the job with the matching ID
        const job = parsedJobData.find((job) => job.id === parsedJobId);
        console.log("Job Details:", job);
    
        if (job) {
          setJobDetails(job);
        } else {
          setError('Job not found');
        }
      } catch (error) {
        setError(error.message);
        console.error('Fetch error:', error);
      } finally {
        setLoading(false);
      }
    };
  
    
    
    fetchJobDetails();
  }, [jobId]); // Re-run when jobId changes

    
  // const handleLoginClick = () => {
  //   localStorage.setItem("jobTitle",role); // Store the role in localStorage
  //   window.location.href =
  //     // "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://shubha.d2m2wtcivnya67.amplifyapp.com/CareerForm";
  //     "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/CareerForm";
  //   // Use the appropriate redirect URL for your deployment
  // };

  const handleLoginClick = () => {
    if (jobDetails?.role) {
      localStorage.setItem("jobTitle", jobDetails.role); // Store the role in localStorage
    }
    window.location.href =
    "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://www.dhruthzucitech.solutions/CareerForm";
      // "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/CareerForm";
    // Use the appropriate redirect URL for your deployment
  };
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Container style={{ marginTop: "120px" }}>
      <div
        className="section-title"
        data-aos="fade-up"
        style={{
          textAlign: "center",
          padding: "10px",
          borderRadius: "20px",
        }}
      >
        <h1
          style={{
            fontFamily: "impact",
            margin: "0",
          }}
        >
          {jobDetails?.role || "Job Role"}
        </h1>
      </div>

      <Row className="my-4">
        <Col>
          <h5>Employment Type:</h5>
          <p>{jobDetails?.EmploymentType || "Not specified"}</p>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>Job Overview:</h5>
          <p>{jobDetails?.JobOverview || "No overview available"}</p>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>Roles and Responsibilities:</h5>
          <ul>
            {jobDetails?.RolesAndResponsibilities && typeof jobDetails.RolesAndResponsibilities === "string" ? (
              jobDetails.RolesAndResponsibilities.split("\n").map((responsibility, index) => (
                <li key={index}>{responsibility}</li>
              ))
            ) : (
              <li>No responsibilities available.</li>
            )}
          </ul>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>Skills and Qualifications:</h5>
          <ul>
            {jobDetails?.SkillsAndQualifications && typeof jobDetails.SkillsAndQualifications === "string" ? (
              jobDetails.SkillsAndQualifications.split("\n").map((skill, index) => (
                <li key={index}>{skill}</li>
              ))
            ) : (
              <li>No skills or qualifications available.</li>
            )}
          </ul>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>What We Offer:</h5>
          <ul>
            {jobDetails?.WhatWeOffer && typeof jobDetails.WhatWeOffer === "string" ? (
              jobDetails.WhatWeOffer.split("\n").map((offer, index) => (
                <li key={index}>{offer}</li>
              ))
            ) : (
              <li>No offers available.</li>
            )}
          </ul>
        </Col>
      </Row>

      <Button
        variant="dark"
        className="apply-now-button"
        onClick={handleLoginClick}
        style={{ marginBottom: "40px" }}
      >
        Apply Now <span className="arrow">→</span>
      </Button>
    </Container>
  );
};

export default JobRolePage;