import React, { useRef } from "react";
import {
  Container,
  Carousel,
  Button,
  Image,
} from "react-bootstrap";
import Clients from "../Clients";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  backgroundImage,
} from "../../../Assets";
import AboutSection from "../../helpers/Aboutsection";
import Hometabs from "./HomeTabs";
import NavBar from "../../Navbar/Navbar";
import Mvm2 from "../../../Assets/Mvm2.png"; // Add this import statement
import { useNavigate } from "react-router-dom"; // Add this import statement


const Home = () => {
  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 1 } },
  };
  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
  };
  const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 1 } },
  };
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const aboutContainerRef = useRef(null);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleGetStartedClick = () => {
    const aboutContainerOffset = aboutContainerRef.current.offsetTop - 120; // Subtract the height of your navigation bar
    window.scrollTo({
      top: aboutContainerOffset,
      behavior: "smooth",
    });
  };

  const handleKnowMoreClick = () => {
    navigate("/ModernWorkspaceManagement"); // Navigate to the MWMPage
  };

  return (
    <div>
      <NavBar />
      <section className="hero-section" style={{ position: "relative", height: "100vh" }}>
        <Carousel>
          <Carousel.Item>
            <div
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <motion.h1
                  className="text-center mt-4"
                  initial="hidden"
                  animate="visible"
                  variants={headerVariants}
                  ref={ref}
                  style={{
                    fontFamily: "impact",
                    fontSize: "2.5rem",
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    color: "#FFFFFF",
                  }}
                >
                  Welcome to Dhruthzuci Tech Solutions
                </motion.h1>
                <motion.p
                  className="text-center mt-3"
                  initial="hidden"
                  animate="visible"
                  variants={contentVariants}
                  style={{
                    fontFamily: "impacted",
                    fontSize: "1.2rem",
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
                    color: "#FFFFFF",
                  }}
                >
                  Digital Transformation Journey Begins With Dhruthzuci Tech
                </motion.p>
                <Button
                  onClick={handleGetStartedClick}
                  style={{
                    background: "linear-gradient(to top, #387FD3, #7B3EAC)",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    cursor: "pointer",
                    fontSize: "1.2rem",
                    marginTop: "20px",
                  }}
                >
                  Get Started
                </Button>
              </Container>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                backgroundImage: `url(${Mvm2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <motion.h1
                  className="text-center mt-4"
                  initial="hidden"
                  animate="visible"
                  variants={headerVariants}
                  ref={ref}
                  style={{
                    fontFamily: "impact",
                    fontSize: "2.5rem",
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    color: "#FFFFFF",
                  }}
                >
                 
                </motion.h1>
                <motion.p
                  className="text-center mt-3"
                  initial="hidden"
                  animate="visible"
                  variants={contentVariants}
                  style={{
                    fontFamily: "impacted",
                    fontSize: "1.2rem",
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
                    color: "#FFFFFF",
                  }}
                >
                  
                </motion.p>
                <Button
                  onClick={handleKnowMoreClick}
                  style={{
                    background: "linear-gradient(to top, #387FD3, #7B3EAC)",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "15px",
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    cursor: "pointer",
                    fontSize: "1.2rem",
                    marginTop: "160px",
                  }}
                >
                  Know More
                </Button>
              </Container>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>

      <div style={{ marginTop: "120px" }}>
        <Container ref={aboutContainerRef} id="aboutContainer">
          <Hometabs />
          <AboutSection />
        </Container>
        {/* <Clients /> */}
      </div>
    
    </div>
  );
};

export default Home;