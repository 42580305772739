import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LOGO } from "../../Assets";
import {
  FaHome,
  FaClipboard,
  FaCogs,
  FaUsers,
  FaEnvelope,
  FaColumns,
  FaProductHunt,
  FaCalendarAlt,
} from "react-icons/fa";
import "./navstyles.css";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <Navbar
      expand="lg"
      collapseOnSelect
      style={{
        position: "fixed",
        width: "100%",
        zIndex: 1000,
        backgroundColor: "#0C1A4A",
        height: "100px",
      }}
      variant="dark"
    >
      <Container
        style={{
          backgroundColor: "#0C1A4A",
          borderRadius: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Navbar.Brand href="/">
          <img
            src={LOGO}
            alt="Company Logo"
            style={{
              width: "280px", // Set a constant width for the logo
              marginLeft: "20px",
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ color: "white", border: "none" }}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <Nav.Link href="/" style={{ color: "white" }}>
              <FaHome size={20} /> Home
            </Nav.Link>
            <Nav.Link href="/services" style={{ color: "white" }}>
              <FaClipboard size={20} /> Services
            </Nav.Link>
            <Nav.Link href="/solutions" style={{ color: "white" }}>
              <FaCogs size={20} /> Solutions
            </Nav.Link>
            <Nav.Link href="/blogs" style={{ color: "white" }}>
              <FaColumns size={20} /> Blogs
            </Nav.Link>
            <Nav.Link href="/products" style={{ color: "white" }}>
              <FaProductHunt size={20} /> Products
            </Nav.Link>
            <Nav.Link href="/aboutus" style={{ color: "white" }}>
              <FaUsers size={20} /> About
            </Nav.Link>
            <Nav.Link href="/contactus" style={{ color: "white" }}>
              <FaEnvelope size={20} /> Contact
            </Nav.Link>
            <Nav.Link href="/events" style={{ color: "white" }}>
              <FaCalendarAlt size={20} /> Events
            </Nav.Link>

            {/* Careers Dropdown */}
            <NavDropdown
            
              title={
                <>
                  <FaEnvelope size={20} /> Careers
                </>
              }
              id="careers-dropdown"
              style={{ color: "white" }}
            >
              <NavDropdown.Item href="/careers/view-status">
                View Application Status
              </NavDropdown.Item>
              <NavDropdown.Item href="/careers">
                View Openings
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
