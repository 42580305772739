
export const AWS_API_KEY = "abTLSGqgK19iPy8i5kI0Y3DK9vgoAVoD4qiK6nmw";

export const COMPANYNAME = "Dhruthzuci Tech Solutions ";
export const servicesData = [
  {
    title: "Cloud Advisory",
    rout: "/cloudAdvisory",
    description:
      "Cloud has the potential to revolutionize business, but without guidance, it can prompt some daunting decisions in terms of adoption strategy, platform selection, and cost modeling.",
    img: require("../Assets/images/CloudAdvisory.png"),
  },
  {
    title: "Professional Services",
    rout: "/professionalServices",
    description:
      "Cloud Professional Services creates a cloud-based technology infrastructure that adapts to and evolves alongside your business. Choose from hybrid to cloud-based solutions, depending on the level of cloud computing you would like to implement and the resources you want to utilize.",
    img: require("../Assets/images/ProfessionalServices.png"),
  },
  {
    title: "BlockChain",
    rout: "/blockChain",
    description:
      "At a cloud-based management organisation, we offer a variety of platforms and solutions that make use of blockchain technology to enable decentralised, secure, and transparent data storage and smart contract execution.",
    img: require("../Assets/images/blockChain.png"),
  },
  {
    title: "Microsoft Office 365 Automation",
    rout: "/microSoft",
    description:
      "Our Team understands the importance of having a seamless and efficient productivity suite that empowers your business to collaborate, communicate, and thrive in the modern workplace. With our expertise in Microsoft Office 365, we help businesses unlock the full potential of this powerful platform.",
    img: require("../Assets/images/office.png"),
  },
  {
    title: "Full Stack Development",
    rout: "/fullstack",
    description:
      "With the app development industry reflecting the popularity of enterprise mobility, it is significant to take your business on the internet. A high-performing business application that is easily navigable and impresses your audience for the first time is the need of the hour.",
    img: require("../Assets/images/FullStackDevelopment.png"),
  },
  {
    title: "VAPT",
    rout: "/VAPT",
    description:
      "Cloud has the potential to revolutionize business, but without guidance, it can prompt some daunting decisions in terms of adoption strategy, platform selection, and cost modeling.",
    img: require("../Assets/images/VAPT.png"),
  },
];

//solution data

export const solutionsData = [
  {
    title: "Landing Zone",
    description:
      "As a select AWS Consulting Partner, we understand the challenges of setting up a reliable and secure Cloud environment that meets your business needs. Our team of experienced Cloud consultants can navigate you quickly and efficiently to set up a Landing Zone for your small, medium, or large workloads. Our services are tailored to your specific business needs, and we are committed to delivering high-quality results to achieve your business goals.",
    image: require("../Assets/images/landing.png"),
  },
  {
    title: "Cloud Resource Scheduler",
    description:
      "The Solution for Efficient Cloud Resource Management Our tool automates the allocation and management of cloud computing resources, allowing you to schedule when and how much resources should be used based on demand and availability.With Cloud Resource Scheduler, you can:",
    image: require("../Assets/images/cre.png"),
  },
  {
    title: "Cloud Cost Optimizer",
    description:
      "A cutting-edge Cloud Native tool designed to help Business maximize the value of their cloud infrastructure investment. Our AI-powered technology provides deep insights into your cloud spend and offers actionable recommendations to help you optimize your costs. With customizable algorithms, our solution is designed to meet the specific needs of different Bussiness sizes.",
    image: require("../Assets/images/Cloud-cost.png"),
  },
  {
    title: "Conversation Chat Bots",
    description:
      "With our chatbot, businesses can improve the customer experience and increase their overall efficiency. The chatbot is capable of handling a variety of inquiries and providing prompt and accurate answers. Integration with popular messaging platforms and websites makes the chatbot user-friendly and easily accessible to customers. If needed, the chatbot can escalate the conversation to a live agent for further assistance.",
    image: require("../Assets/images/ChatBot.png"),
  },
  {
    title: "Cloud Contact Center",
    description:
      "Our comprehensive contact/support center solution utilizes cutting-edge voice and text technologies to create a cost-effective and efficient support center for your business.Here's what you can expect with our solution:",
    image: require("../Assets/images/CloudContactCenter.png"),
  },
  {
    title: "Artificial Intelligence /Machine Learning",
    description:
      "We specialize in delivering advanced AI/ML services, empowering businesses to unlock the full potential of artificial intelligence and machine learning technologies. With our comprehensive range of services, we help businesses harness the power of visual data, automate processes, and gain valuable insights to drive innovation and achieve sustainable growth in today's data-driven landscape.",
    image: require("../Assets/images/sw.png"),
  },
];
export const productsData = [
  {
    title: "Modern Workspace Mangement",
    description:
      "Efficient, secure digital workspaces are essential for productivity. Setting up Modern Workspace Management environments manually can lead to delays and inconsistencies. With automated MWM deployment, organizations can achieve quick, consistent setups across environments, minimizing errors and boosting reliability. Tailored solutions provide a seamless, high-performance workspace aligned with business needs, allowing teams to focus on growth and innovation.",
    image: require("../Assets/image.png"),
  },
  {
    title: "3rdEye",
    description:
      "3rdeye is a state-of-the-art web platform designed to revolutionize the way we classify agricultural produce.Utilizing advanced machine learning and AWS technologies, 3rdeye offers real-time, accurate classification of various crop types, classes, and varieties.",
    image: require("../Assets/images/cre.png"),
  },
  {
    title: "Seedzhub",
    description:
      "SeedzHub is a cutting-edge platform designed to revolutionize the seed-to-sapling journey, blending advanced AI technology with global collaboration.Whether you are a researcher, farmer, or part of an agricultural organization, SeedzHub empowers you to make data-driven decisions, enhance seed productivity, and support sustainable agricultural practices.",
    image: require("../Assets/images/Cloud-cost.png"),
  },
  
];
export const CareersData = [
  // {
  //   title: "Intern",
  //   rout: "/JobDescription",
  //   position: "Intern",
  //   description:
  //     "We are looking for a motivated and talented Software Development Intern to join our team. This internship will provide hands-on experience in software development, working alongside experienced professionals. You will have the opportunity to contribute to real projects and gain valuable skills in [specific technologies or tools your company uses, e.g., React, Node.js, AWS].",
  // },
  {
    title: "Cloud Engineer",
    rout: "/JobDescription/CloudEngineer",
    position: "Employment",
    description:
      "As a Cloud Operations Engineer at DZ Tech, You'll work closely with the Engineering and Operations teams to streamline workflows and automation, reduce costs and non-conformance, and ensure effective monitoring, testing, and deployment. Your responsibilities will include troubleshooting system issues, participating in on-call rotations, conducting root cause analyses of incidents, and improving monitoring solutions to minimize false positives and identify service health regressions. Additionally, you'll communicate findings to the application team and generate weekly data reports summarizing the application's health. You'll also be responsible for configuring and managing cloud infrastructure and applications, including cross-technology administration, scripting, and monitoring automation execution, with a strong focus on incident management and service restoration.  ",
  },
  // {
  //   title: "Application engineer",
  //   rout: "/JobDescription",
  //   description:
  //     "As an Entry-Level Application Engineer, you will be involved in the design, development, and maintenance of software applications. You will work closely with cross-functional teams to understand user requirements, write efficient code, and support the deployment of applications in a production environment.",
  // },
  {
    title: "Cloud Engineer/Associate",
    position: "Intern",
    rout: "/JobDescription",
    description:
      "As an Entry-Level Application Engineer, you will be involved in the design, development, and maintenance of software applications. You will work closely with cross-functional teams to understand user requirements, write efficient code, and support the deployment of applications in a production environment.",
  },
  {
    title: "UI/UX Designer",
    position: "Intern",
    rout: "/JobDescription",
    description:
      "As an Entry-Level Application Engineer, you will be involved in the design, development, and maintenance of software applications. You will work closely with cross-functional teams to understand user requirements, write efficient code, and support the deployment of applications in a production environment.",
  },
  // {
  //   title: "Developer / Data science ",
  //   position: "Intern",
  //   rout: "/JobDescription",
  //   description:
  //     "As an Entry-Level Application Engineer, you will be involved in the design, development, and maintenance of software applications. You will work closely with cross-functional teams to understand user requirements, write efficient code, and support the deployment of applications in a production environment.",
  // },
  // {
  //   title: "Network Engineer/Associate",
  //   position: "Intern",
  //   rout: "/JobDescription",
  //   description:
  //     "As an Entry-Level Application Engineer, you will be involved in the design, development, and maintenance of software applications. You will work closely with cross-functional teams to understand user requirements, write efficient code, and support the deployment of applications in a production environment.",
  // },
  {
    title: "Intern / Employment",
    position: "Intern / Employment",
    rout: "/JobDescription",
    description:
      "As an Entry-Level Application Engineer, you will be involved in the design, development, and maintenance of software applications. You will work closely with cross-functional teams to understand user requirements, write efficient code, and support the deployment of applications in a production environment.",
  },
  // {
  //   title: "program co-ordinator or manager",
  //   position: "Intern",
  //   rout: "/JobDescription",
  //   description:
  //     "As an Entry-Level Application Engineer, you will be involved in the design, development, and maintenance of software applications. You will work closely with cross-functional teams to understand user requirements, write efficient code, and support the deployment of applications in a production environment.",
  // },

];

export const PortfolioData = [
  {
    title: "Infrastructure as a Service",
    iconClass: "fa fa-quote-left text-primary",
    content:
      "Take advantage of cloud infrastructure's scalability and flexibility. Our IaaS solutions make it simple to deploy servers, storage, and networking resources.",
  },
  {
    title: "Software as a Service",
    iconClass: "fa fa-quote-left text-success",
    content:
      "Our SaaS services are designed to help you streamline your operations. Access premium software applications without the worry of upkeep and updates.",
  },
  {
    title: "Platform as a Service",
    iconClass: "fa fa-quote-left text-danger",
    content:
      "With our PaaS solutions, you can easily build, launch, and scale applications. Concentrate on innovation while we take care of the underlying infrastructure.",
  },
  {
    title: "Cloud Security",
    iconClass: "fa fa-quote-left text-info",
    content:
      "Use our complete cloud security solutions to safeguard your digital assets. We keep your data secure in the cloud so you can focus on expansion.",
  },
  {
    title: "Managed Services",
    iconClass: "fa fa-quote-left text-purple",
    content:
      "Our managed services make cloud management easier. Our professionals are available 24 hours a day, 7 days a week to monitor and improve your cloud environment.",
  },
];

// Blogs Content

export const Suhas = [
  {
    title: "A Smart Blog on Cloud Migration that Works 24/7 for Your Company",
    author: "Suhas A",
    content: [
      {
        type: "image",
        src: "https://bootdey.com/img/Content/bg1.jpg",
        alt: "",
        fluid: true,
        className: "mb-3",
        style: {
          maxWidth: "100%"
        }
      },
      {
        type: "text",
        content: "Vulnerability Assessment and Penetration Testing (VAPT) is a comprehensive approach to evaluating and enhancing the security of a computer system, network, or application. It combines two activities: vulnerability assessment and penetration testing."
      },
      {
        type: "list",
        style: {
          listStyleType: "decimal",
          fontSize: "16px",
          textAlign: "justify"
        },
        items: [
          "The vulnerability assessment process involves scanning and identifying potential vulnerabilities in a system, network, or application. Automated tools are typically used to search for known security weaknesses, misconfigurations, or outdated software versions. The goal is to identify areas of potential risk.",
          "Penetration testing, also known as ethical hacking, simulates real-world attacks to identify vulnerabilities and test the system's ability to withstand them. Skilled security professionals attempt to exploit identified vulnerabilities to assess the system's security posture."
        ]
      },
      {
        type: "text",
        content: "Benefits of VAPT:"
      },
      {
        type: "list",
        style: {
          listStyleType: "decimal",
          fontSize: "16px",
          textAlign: "justify"
        },
        items: [
          "VAPT helps identify potential vulnerabilities and security weaknesses that could be exploited by attackers. By proactively identifying and addressing these issues, organizations can mitigate risks and prevent security breaches.",
          "VAPT can assist organizations in meeting regulatory compliance requirements. Many industry standards and regulations, such as the Payment Card Industry Data Security Standard (PCI DSS) or General Data Protection Regulation (GDPR), mandate regular security assessments.",
          "VAPT strengthens an organization's security defenses by uncovering vulnerabilities that might otherwise go unnoticed. It provides insights into the security posture and offers recommendations to enhance security controls, policies, and procedures.",
          "Demonstrating a commitment to security through VAPT can enhance customer trust and confidence. It shows that an organization takes security seriously and is dedicated to protecting sensitive information."
        ]
      },
      {
        type: "text",
        content: "VAPT on Cloud Infrastructure:"
      },
      {
        type: "text",
        content: "For any cloud infrastructure, performing a VAPT is crucial to ensure its safety and freedom from vulnerabilities. When conducting a VAPT on cloud environments, it is recommended to engage experienced security professionals or security service providers with expertise in cloud security and cloud-specific assessments."
      },
      {
        type: "text",
        content: "When performing a Vulnerability Assessment and Penetration Testing (VAPT) on AWS (Amazon Web Services) infrastructure, various AWS services and tools can enhance the assessment process. Some AWS services that can be beneficial for conducting VAPT on AWS are:"
      },
      {
        type: "list",
        style: {
          listStyleType: "lower-roman",
          fontSize: "16px",
          textAlign: "justify"
        },
        items: [
          "Amazon Inspector",
          "AWS Config",
          "AWS CloudTrail",
          "Amazon GuardDuty"
        ]
      }
    ]
  }
];

export const BlogData = [
  {
    title: "Infrastructure as a Service",
    iconClass: "fa fa-quote-left text-primary",
    content:
      "Take advantage of cloud infrastructure's scalability and flexibility. Our IaaS solutions make it simple to deploy servers, storage, and networking resources.",
  },
  {
    title: "Software as a Service",
    iconClass: "fa fa-quote-left text-success",
    content:
      "Our SaaS services are designed to help you streamline your operations. Access premium software applications without the worry of upkeep and updates.",
  },
  {
    title: "Platform as a Service",
    iconClass: "fa fa-quote-left text-danger",
    content:
      "With our PaaS solutions, you can easily build, launch, and scale applications. Concentrate on innovation while we take care of the underlying infrastructure.",
  },
  {
    title: "Cloud Security",
    iconClass: "fa fa-quote-left text-info",
    content:
      "Use our complete cloud security solutions to safeguard your digital assets. We keep your data secure in the cloud so you can focus on expansion.",
  },
  {
    title: "Managed Services",
    iconClass: "fa fa-quote-left text-purple",
    content:
      "Our managed services make cloud management easier. Our professionals are available 24 hours a day, 7 days a week to monitor and improve your cloud environment.",
  },
];