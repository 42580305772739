// import React, { useState } from "react";
// import { Form, Button, Container, Row, Col } from "react-bootstrap";
// import { BACKGROUNDCOLOR } from "../../Themes/theme";

// const MWMform = () => {
//   const [otherWorkload, setOtherWorkload] = useState("");
//   const [otherGoal, setOtherGoal] = useState("");

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form submitted");
//   };

//   return (
//      <div
//         style={{
//           background: BACKGROUNDCOLOR,
//         }}
//       >
   
//     <Container style={{ marginTop: "120px",marginBottom: "20px" }}>
//           <div
//             className="section-title"
//             data-aos="fade-up"
//             style={{
//               textAlign: "center",
//               padding: "10px",
//               borderRadius: "20px",
//             }}
//           >
       
        
//           <h2 className="mb-4 text-center text-primary">Workspace Needs</h2>
//           </div>
//           <Row className="justify-content-center">
//             <Col md={8} sm={12} className="shadow-lg p-4 bg-white rounded">
          
//             <Form onSubmit={handleSubmit}>

//               {/* Question 1 */}
//               <Form.Group className="mb-4">
//                 <Form.Label>What is the size of your team?</Form.Label>
//                 <Form.Select aria-label="Team size select" className="form-control">
//                   <option>1–10 users</option>
//                   <option>11–50 users</option>
//                   <option>51–200 users</option>
//                   <option>201+ users</option>
//                 </Form.Select>
//               </Form.Group>

//               {/* Question 2 */}
//               <Form.Group className="mb-4">
//                 <Form.Label>What kind of workloads do your users typically handle?</Form.Label>
//                 <Form.Check type="radio" label="Data processing or analysis" name="workload" />
//                 <Form.Check type="radio" label="Creative design or multimedia" name="workload" />
//                 <Form.Check type="radio" label="Software development or IT management" name="workload" />
//                 <Form.Check type="radio" label="General office productivity (email, documents, etc.)" name="workload" />
//                 <Form.Check type="radio">
//                   <Form.Check.Input
//                     type="radio"
//                     name="workload"
//                     onChange={(e) => setOtherWorkload(e.target.checked ? "" : otherWorkload)}
//                   />
//                   <Form.Check.Label>Other (please specify)</Form.Check.Label>
//                 </Form.Check>
//                 {otherWorkload && (
//                   <Form.Control
//                     type="text"
//                     placeholder="Specify other workload"
//                     className="mt-2"
//                     value={otherWorkload}
//                     onChange={(e) => setOtherWorkload(e.target.value)}
//                   />
//                 )}
//               </Form.Group>

//               {/* Question 3 */}
//               <Form.Group className="mb-4">
//                 <Form.Label>Which features are most important for your workspace? (Select all that apply)</Form.Label>
//                 <Form.Check type="checkbox" label="Secure remote access" />
//                 <Form.Check type="checkbox" label="Auto-scaling for user load" />
//                 <Form.Check type="checkbox" label="Backup and disaster recovery" />
//                 <Form.Check type="checkbox" label="Integration with existing tools (Microsoft 365, GitHub, etc.)" />
//                 <Form.Check type="checkbox" label="Cost optimization (e.g., auto start/stop for virtual desktops)" />
//                 <Form.Check type="checkbox" label="Governance and compliance" />
//               </Form.Group>

//               {/* Question 4 */}
//               <Form.Group className="mb-4">
//                 <Form.Label>What is your preferred cloud provider?</Form.Label>
//                 <Form.Select aria-label="Cloud provider select" className="form-control">
//                   <option>Microsoft Azure</option>
//                   <option>AWS</option>
//                   <option>Google Cloud</option>
//                   <option>Multi-cloud</option>
//                 </Form.Select>
//               </Form.Group>

//               {/* Question 5 */}
//               <Form.Group className="mb-4">
//                 <Form.Label>Do you have existing virtual desktop infrastructure (VDI)?</Form.Label>
//                 <Form.Check type="radio" label="Yes, and I am looking to migrate or upgrade" name="vdi" />
//                 <Form.Check type="radio" label="No, I am setting up VDI for the first time" name="vdi" />
//                 <Form.Check type="radio" label="Not sure" name="vdi" />
//               </Form.Group>

//               {/* Question 6 */}
//               <Form.Group className="mb-4">
//                 <Form.Label>What is your primary goal for adopting a workspace solution?</Form.Label>
//                 <Form.Check type="radio" label="Improve productivity" name="goal" />
//                 <Form.Check type="radio" label="Reduce IT overhead" name="goal" />
//                 <Form.Check type="radio" label="Enhance security and compliance" name="goal" />
//                 <Form.Check type="radio" label="Enable remote or hybrid work" name="goal" />
//                 <Form.Check type="radio">
//                   <Form.Check.Input
//                     type="radio"
//                     name="goal"
//                     onChange={(e) => setOtherGoal(e.target.checked ? "" : otherGoal)}
//                   />
//                   <Form.Check.Label>Other (please specify)</Form.Check.Label>
//                 </Form.Check>
//                 {otherGoal && (
//                   <Form.Control
//                     type="text"
//                     placeholder="Specify other goal"
//                     className="mt-2"
//                     value={otherGoal}
//                     onChange={(e) => setOtherGoal(e.target.value)}
//                   />
//                 )}
//               </Form.Group>

//               <Button variant="primary" type="submit" className="w-100 mt-4" style={{ padding: "10px 0", fontSize: "16px" }}>
//                 Submit
//               </Button>
//             </Form>
//           </Col>
//         </Row>
//       </Container>
   
//     </div>
//   );
// };

// export default MWMform;

import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

const MWMform = () => {
  const [otherWorkload, setOtherWorkload] = useState("");
  const [otherGoal, setOtherGoal] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission
    console.log("Form submitted");
  };

  return (
    <div
      style={{
        background: BACKGROUNDCOLOR,
        minHeight: "100vh", 
        paddingTop: "50px", 
      }}
    >
     <Container style={{ marginTop: "120px",marginBottom: "20px" }}>
        <div
          className="section-title"
          data-aos="fade-up"
          style={{
            textAlign: "center",
            marginBottom: "30px"
          }}
        >
          <h1
            style={{
              fontFamily: "impact",
              margin: "0",
            }}
          >
            WORKSPACE NEEDS
          </h1>
        </div>

        <Row className="justify-content-center">
          <Col md={8} sm={12} className="shadow-lg p-4 bg-white rounded">
            <Form onSubmit={handleSubmit}>
              <Row>
                {/* Question 1 */}
                <Col md={6} sm={12} className="mb-4">
                  <Form.Group>
                    <Form.Label>What is the size of your team?</Form.Label>
                    <Form.Select aria-label="Team size select" className="form-control">
                      <option>1–10 users</option>
                      <option>11–50 users</option>
                      <option>51–200 users</option>
                      <option>201+ users</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/* Question 4 */}
                <Col md={6} sm={12} className="mb-4">
                  <Form.Group>
                    <Form.Label>What is your preferred cloud provider?</Form.Label>
                    <Form.Select aria-label="Cloud provider select" className="form-control">
                      <option>Microsoft Azure</option>
                      <option>AWS</option>
                      <option>Google Cloud</option>
                      <option>Multi-cloud</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              {/* Question 2 */}
              <Form.Group className="mb-4">
                <Form.Label>What kind of workloads do your users typically handle?</Form.Label>
                <Form.Check type="radio" label="Data processing or analysis" name="workload" />
                <Form.Check type="radio" label="Creative design or multimedia" name="workload" />
                <Form.Check type="radio" label="Software development or IT management" name="workload" />
                <Form.Check type="radio" label="General office productivity (email, documents, etc.)" name="workload" />
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    name="workload"
                    onChange={(e) => setOtherWorkload(e.target.checked ? "" : otherWorkload)}
                  />
                  <Form.Check.Label>Other (please specify)</Form.Check.Label>
                </Form.Check>
                {otherWorkload && (
                  <Form.Control
                    type="text"
                    placeholder="Specify other workload"
                    className="mt-2"
                    value={otherWorkload}
                    onChange={(e) => setOtherWorkload(e.target.value)}
                  />
                )}
              </Form.Group>

              {/* Question 3 */}
              <Form.Group className="mb-4">
                <Form.Label>Which features are most important for your workspace? (Select all that apply)</Form.Label>
                <Form.Check type="checkbox" label="Secure remote access" />
                <Form.Check type="checkbox" label="Auto-scaling for user load" />
                <Form.Check type="checkbox" label="Backup and disaster recovery" />
                <Form.Check type="checkbox" label="Integration with existing tools (Microsoft 365, GitHub, etc.)" />
                <Form.Check type="checkbox" label="Cost optimization (e.g., auto start/stop for virtual desktops)" />
                <Form.Check type="checkbox" label="Governance and compliance" />
              </Form.Group>

              {/* Question 5 */}
              <Form.Group className="mb-4">
                <Form.Label>Do you have existing virtual desktop infrastructure (VDI)?</Form.Label>
                <Form.Check type="radio" label="Yes, and I am looking to migrate or upgrade" name="vdi" />
                <Form.Check type="radio" label="No, I am setting up VDI for the first time" name="vdi" />
                <Form.Check type="radio" label="Not sure" name="vdi" />
              </Form.Group>

              {/* Question 6 */}
              <Form.Group className="mb-4">
                <Form.Label>What is your primary goal for adopting a workspace solution?</Form.Label>
                <Form.Check type="radio" label="Improve productivity" name="goal" />
                <Form.Check type="radio" label="Reduce IT overhead" name="goal" />
                <Form.Check type="radio" label="Enhance security and compliance" name="goal" />
                <Form.Check type="radio" label="Enable remote or hybrid work" name="goal" />
                <Form.Check type="radio">
                  <Form.Check.Input
                    type="radio"
                    name="goal"
                    onChange={(e) => setOtherGoal(e.target.checked ? "" : otherGoal)}
                  />
                  <Form.Check.Label>Other (please specify)</Form.Check.Label>
                </Form.Check>
                {otherGoal && (
                  <Form.Control
                    type="text"
                    placeholder="Specify other goal"
                    className="mt-2"
                    value={otherGoal}
                    onChange={(e) => setOtherGoal(e.target.value)}
                  />
                )}
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                className="w-100 mt-4"
                style={{
                  padding: "12px 0",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MWMform;
