// import React from "react";
// import { Container, Row, Col, Card, Button } from "react-bootstrap";
// import {
//   FaDesktop,
//   FaCloud,
//   FaUserShield,
//   FaExpand,
//   FaDollarSign,
//   FaWrench,
//   FaUserTie,
//   FaShieldAlt,
// } from "react-icons/fa";
// import { CloudlandingData } from "../Pages/DiGI/config";

// const MWMPage = () => {
//   const centerAlignedText = {
//     textAlign: "center",
//     fontFamily: "impacted",
//     fontSize: "18px",
//   };
//   const cardStyle = {
//     borderRadius: "15px",
//     boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
//     backgroundColor: "white",
//   };
//   return (
//     <div>
//       <section
//         className="py-5"
//         style={{
//           backgroundImage: `url(${CloudlandingData.heroSection.backgroundImage})`,
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat",
//           height: "100vh",
//           backgroundPosition: "center",
//           position: "relative",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         {/* <Container>    
//   <h1
//       style={{
//         ...centerAlignedText,
//         fontSize: "50px",
//         marginBottom: "300px",
//         marginTop: "250px",
//         color: "#fff",
//       }}
//     >
//       {CloudlandingData.heroSection.title}
//     </h1></Container> */}
//       </section>
//       <Container
//         style={{ marginTop: "100px", fontFamily: "Arial, sans-serif" }}
//       >
//         {/* Header Section */}

//         <Row className="mb-5 text-center d-flex flex-column align-items-center">
//   <Col lg={8} className="mb-4">
//     <h1 style={{ fontWeight: "bold", color: "#007bff" }}>
//       Workspace Management Made Simple and Affordable
//     </h1>
//     <p
//       className="lead"
//       style={{ fontSize: "1.2rem", color: "#6c757d" }}
//     >
//       Empower your team with seamless, scalable workspace solutions—no
//       costly enterprise licenses required.
//     </p>
//   </Col>

//   <Col>
//     <div className="d-flex gap-3 justify-content-center">
//       <Button
//         variant="primary"
//         size="lg"
//         style={{ fontFamily: "impacted", color: "#fff" }}
//         onClick={() => (window.location.href = "/contactus")}
//       >
//         Try It Now
//       </Button>

//       <Button
//         variant="primary"
//         size="lg"
//         style={{ fontFamily: "impacted", color: "#fff" }}
//         onClick={() =>
//           (window.location.href =
//             "https://mwm-dev.dhruthzucitech.solutions/authentication/sign-in")
//         }
//       >
//         Login
//       </Button>
//     </div>
//   </Col>
// </Row>


//         {/* Solutions Section */}
//         <Row className="mb-5 text-center">
//           <Col>
//             <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
//               Solutions for Your Workspace Needs
//             </h2>
//             <p style={{ fontSize: "1rem", color: "#6c757d" }}>
//               From effortless setup to scalable management, we've got you
//               covered.
//             </p>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6} lg={3} className="mb-4">
//             <Card className="shadow-sm border-0">
//               <Card.Body>
//                 <FaDesktop size={40} className="mb-3 text-primary" />
//                 <Card.Title className="font-weight-bold">
//                   Effortless Workspace Setup
//                 </Card.Title>
//                 <Card.Text>
//                   Our intuitive platform allows your team to set up, manage, and
//                   optimize workspaces without specialized IT support or costly
//                   licenses.
//                 </Card.Text>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col md={6} lg={3} className="mb-4">
//             <Card className="shadow-sm border-0">
//               <Card.Body>
//                 <FaCloud size={40} className="mb-3 text-success" />
//                 <Card.Title className="font-weight-bold">
//                   Access Anywhere, Anytime
//                 </Card.Title>
//                 <Card.Text>
//                   Secure, remote access to personalized virtual desktops. Work
//                   seamlessly on-the-go from any device.
//                 </Card.Text>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col md={6} lg={3} className="mb-4">
//             <Card className="shadow-sm border-0">
//               <Card.Body>
//                 <FaUserShield size={40} className="mb-3 text-warning" />
//                 <Card.Title className="font-weight-bold">
//                   Flexible User Controls
//                 </Card.Title>
//                 <Card.Text>
//                   Admins create and manage workspaces effortlessly while users
//                   maintain control, boosting productivity.
//                 </Card.Text>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col md={6} lg={3} className="mb-4">
//             <Card className="shadow-sm border-0">
//               <Card.Body>
//                 <FaExpand size={40} className="mb-3 text-danger" />
//                 <Card.Title className="font-weight-bold">
//                   Scalable and Cost-Effective
//                 </Card.Title>
//                 <Card.Text>
//                   Pay for what you need, when you need it. Transparent pricing
//                   with no hidden fees.
//                 </Card.Text>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>

//         {/* Benefits Section */}
//         <Container style={{ marginTop: "5rem" }}>
//           <Row className="mb-5 text-center">
//             <Col>
//               <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
//                 Benefits from a Business Perspective
//               </h2>
//               <p style={{ fontSize: "1rem", color: "#6c757d" }}>
//                 Discover how Dhruthzuci transforms workspace management for your
//                 business.
//               </p>
//             </Col>
//           </Row>
//           <Row>
//             <Col md={6} lg={3} className="mb-4">
//               <Card className="shadow-sm border-0">
//                 <Card.Body>
//                   <FaDollarSign size={40} className="mb-3 text-primary" />
//                   <Card.Title className="font-weight-bold">
//                     Optimize Operations Without the Cost
//                   </Card.Title>
//                   <Card.Text>
//                     Enjoy functionality without financial burden, saving money
//                     while enhancing efficiency.
//                   </Card.Text>
//                 </Card.Body>
//               </Card>
//             </Col>
//             <Col md={6} lg={3} className="mb-4">
//               <Card className="shadow-sm border-0">
//                 <Card.Body>
//                   <FaWrench size={40} className="mb-3 text-success" />
//                   <Card.Title className="font-weight-bold">
//                     Reduce IT Overhead
//                   </Card.Title>
//                   <Card.Text>
//                     Simplified management portal minimizes IT intervention,
//                     allowing focus on strategic initiatives.
//                   </Card.Text>
//                 </Card.Body>
//               </Card>
//             </Col>
//             <Col md={6} lg={3} className="mb-4">
//               <Card className="shadow-sm border-0">
//                 <Card.Body>
//                   <FaUserTie size={40} className="mb-3 text-warning" />
//                   <Card.Title className="font-weight-bold">
//                     Empower Your Workforce
//                   </Card.Title>
//                   <Card.Text>
//                     Enable faster project delivery and improved collaboration
//                     with modern workspace solutions.
//                   </Card.Text>
//                 </Card.Body>
//               </Card>
//             </Col>
//             <Col md={6} lg={3} className="mb-4">
//               <Card className="shadow-sm border-0">
//                 <Card.Body>
//                   <FaShieldAlt size={40} className="mb-3 text-danger" />
//                   <Card.Title className="font-weight-bold">
//                     Secure and Compliant
//                   </Card.Title>
//                   <Card.Text>
//                     Enterprise-level security with integration via Microsoft
//                     Entra for user authentication and data protection.
//                   </Card.Text>
//                 </Card.Body>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//         {/* User Stories Section */}
//         <Row className="mb-5 text-center">
//           <Col>
//             <h2 className="fw-bold">User Stories & Testimonials</h2>
//             <p className="text-muted">
//               Hear from our happy users about their experiences with Dhruthzuci.
//             </p>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6} className="mb-4">
//             <Card style={cardStyle}>
//               <Card.Body>
//                 <Card.Title>End-User Experience</Card.Title>
//                 <Card.Text>
//                   “With Dhruthzuci’s workspace solution, I can easily access my
//                   work environment from home or on-site without relying on IT
//                   support. It’s a game-changer for our team’s flexibility.” —
//                   Alex, Project Manager at SmartSolutions Co.
//                 </Card.Text>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col md={6} className="mb-4">
//             <Card style={cardStyle}>
//               <Card.Body>
//                 <Card.Title>Business Perspective</Card.Title>
//                 <Card.Text>
//                   “Switching to this solution allowed us to reduce operational
//                   costs by 40% and remove the complexity of managing enterprise
//                   licenses. Our team is now more agile and productive.” — Sarah,
//                   Operations Head at GreenTech Corp.
//                 </Card.Text>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//         {/* Call-to-Action Section */}
//         <Row className="mt-5 text-center">
//           <Col>
//             <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
//               Empowering Businesses of All Sizes
//             </h2>
//             <p style={{ fontSize: "1rem", color: "#6c757d" }}>
//               Scalable, cost-effective workspace management for startups and
//               established companies alike.
//             </p>
//           </Col>
//         </Row>
//         <Row className="justify-content-center">
//           <Col md={6}>
//             <Card className="shadow-sm border-0">
//               <Card.Body className="text-center">
//                 <Card.Title style={{ fontWeight: "bold", color: "#007bff" }}>
//                   Ready to Transform Your Workspace?
//                 </Card.Title>
//                 <Card.Text>
//                   Submit your details to revolutionize how you manage your
//                   workspaces.
//                 </Card.Text>
//                 <Button variant="primary" size="lg" block>
//                   <a
//                     style={{ fontFamily: "impacted", color: "#fff" }}
//                     href="/contactus"
//                   >
//                     Get Started
//                   </a>
//                 </Button>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//       </Container>{" "}
//     </div>
//   );
// };

// export default MWMPage;
import React,{useState} from "react";
import { Container, Row, Col, Card, Button,Modal } from "react-bootstrap";
import './mwm.css';
import mwmImage1 from '../Assets/mwm-1.jpg'; 
import mwmImage2 from '../Assets/mwm-card.jpg'; 
import mwmImage3 from '../Assets/mwm-card2.jpg'; 
import mwmImage4 from '../Assets/mwm-card3.jpg'; 

import {
  FaDesktop,
  FaCloud,
  FaUserShield,
  FaExpand,
  FaDollarSign,
  FaWrench,
  FaUserTie,
  FaShieldAlt,
  FaBorderStyle,
  FaRocket,
  
} from "react-icons/fa";
import { CloudlandingData } from "../Pages/DiGI/config";


const MWMPage = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
    
  };
  const cardStyle = {
    borderRadius: "15px",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
    display:"flex",

  };

  const mwmcardStyle = {
    borderRadius: "20px",
    
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
    backgroundColor: "rgba(248, 248, 248, 0.8)",
    display:"flex",

  };
  // const toggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  // };


  return (
    <div>
      <section
        className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Container>    
  <h1
      style={{
        ...centerAlignedText,
        fontSize: "50px",
        marginBottom: "300px",
        marginTop: "250px",
        color: "#fff",
      }}
    >
      {CloudlandingData.heroSection.title}
    </h1></Container> */}
      </section>
      <Container
        style={{ marginTop: "100px", fontFamily: "Arial, sans-serif" }}
      >
        {/* Header Section */}

        <Row className="mb-5 text-center d-flex flex-column align-items-center">
  <Col lg={8} className="mb-4">
    <h1 style={{ fontWeight: "bold", color: "#007bff" }}>
      Workspace Management Made Simple and Affordable
    </h1>
    <p
      className="lead"
      style={{ fontSize: "1.2rem", color: "#6c757d" }}
    >
      Empower your team with seamless, scalable workspace solutions—no
      costly enterprise licenses required.
    </p>
  </Col>

  <Col>
    <div className="d-flex gap-3 justify-content-center">
      <Button
        variant="primary"
        size="lg"
        style={{ fontFamily: "impacted", color: "#fff" }}
        onClick={() => (window.location.href = "/contactus")}
      >
        Try It Now
      </Button>

      <Button
        variant="primary"
        size="lg"
        style={{ fontFamily: "impacted", color: "#fff" }}
        onClick={() =>
          (window.location.href =
            "https://mwm-dev.dhruthzucitech.solutions/authentication/sign-in")
        }
      >
        Login
      </Button>
    </div>
  </Col>
</Row>


        {/* Solutions Section */}
        <Row className="mb-5 text-center">
          <Col>
            <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
              Solutions for Your Workspace Needs
            </h2>
            <p style={{ fontSize: "1rem", color: "#6c757d" }}>
              From effortless setup to scalable management, we've got you
              covered.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaDesktop size={40} className="mb-3 text-primary" />
                <Card.Title className="font-weight-bold">
                  Effortless Workspace Setup
                </Card.Title>
                <Card.Text>
                  Our intuitive platform allows your team to set up, manage, and
                  optimize workspaces without specialized IT support or costly
                  licenses.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaCloud size={40} className="mb-3 text-success" />
                <Card.Title className="font-weight-bold">
                  Access Anywhere, Anytime
                </Card.Title>
                <Card.Text>
                  Secure, remote access to personalized virtual desktops. Work
                  seamlessly on-the-go from any device.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaUserShield size={40} className="mb-3 text-warning" />
                <Card.Title className="font-weight-bold">
                  Flexible User Controls
                </Card.Title>
                <Card.Text>
                  Admins create and manage workspaces effortlessly while users
                  maintain control, boosting productivity.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaExpand size={40} className="mb-3 text-danger" />
                <Card.Title className="font-weight-bold">
                  Scalable and Cost-Effective
                </Card.Title>
                <Card.Text>
                  Pay for what you need, when you need it. Transparent pricing
                  with no hidden fees.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Benefits Section */}
        <Container style={{ marginTop: "5rem" }}>
          <Row className="mb-5 text-center">
            <Col>
              <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
                Benefits from a Business Perspective
              </h2>
              <p style={{ fontSize: "1rem", color: "#6c757d" }}>
                Discover how Dhruthzuci transforms workspace management for your
                business.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3} className="mb-4">
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <FaDollarSign size={40} className="mb-3 text-primary" />
                  <Card.Title className="font-weight-bold">
                    Optimize Operations Without the Cost
                  </Card.Title>
                  <Card.Text>
                    Enjoy functionality without financial burden, saving money
                    while enhancing efficiency.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3} className="mb-4">
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <FaWrench size={40} className="mb-3 text-success" />
                  <Card.Title className="font-weight-bold">
                    Reduce IT Overhead
                  </Card.Title>
                  <Card.Text>
                    Simplified management portal minimizes IT intervention,
                    allowing focus on strategic initiatives.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3} className="mb-4">
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <FaUserTie size={40} className="mb-3 text-warning" />
                  <Card.Title className="font-weight-bold">
                    Empower Your Workforce
                  </Card.Title>
                  <Card.Text>
                    Enable faster project delivery and improved collaboration
                    with modern workspace solutions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3} className="mb-4">
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <FaShieldAlt size={40} className="mb-3 text-danger" />
                  <Card.Title className="font-weight-bold">
                    Secure and Compliant
                  </Card.Title>
                  <Card.Text>
                    Enterprise-level security with integration via Microsoft
                    Entra for user authentication and data protection.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        
        <Row className="mb-5 text-center">
          <Col>
            <h2 className="fw-bold">Use cases of Modern Workspace Management (MWM) Solution</h2>
            <p className="text-muted">
              
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
          <Card
              className="mwm-card"
              style={{
                backgroundImage: `url(${mwmImage1})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat', 
                height: '500px' 
              }}
            >
              <div className="blog-card spring-fever">
                <div className="title-content">
                  <h3>Startup</h3>
                </div>
                <div className="card-info">
                  <strong>Use Case:</strong> Scalable, Low-Cost Workspace Management<br />
                  <strong>• Scenario:</strong> Startups need flexible and cost-efficient solutions to onboard teams, manage virtual workspaces, and scale operations as they grow.<br />
                  <strong>• Solution:</strong> Use a self-service portal to quickly provision cloud-based virtual desktops for employees.<br />
                  <ul>
                    <li>Integrate cost-optimization features like automated start/stop scheduling to reduce cloud expenses.</li>
                    <li>Centralize workspace governance using tagging and backup automation.</li>
                    <li>Provide secure login and access management to ensure data security for growing teams.</li>
                  </ul>
                  <strong>Benefits:</strong> Rapid scalability, lower operational costs, and secure collaboration for fast-moving teams.
                </div>
              </div>
            </Card>
          </Col>

              
            
          <Col md={6} className="mb-4">
            <Card
              className="mwm-card"
              style={{
                backgroundImage: `url(${mwmImage2})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat', 
                height: '500px' 
              }}
            >
              <div className="blog-card spring-fever">
                <div className="title-content">
                  <h3>Development teams</h3>
                </div>
                <div className="card-info">
                  <strong>Use Case:</strong> Unified and Optimized Virtual Development Environments<br />
                  <strong>• Scenario:</strong> Development teams often require on-demand access to high-performance virtual machines, standardized environments, and secure code repositories.<br />
                  <strong>• Solution:</strong> Provision Azure Virtual Desktops or AWS Workspaces pre-configured with developer tools (e.g., IDEs, Docker, GitHub).<br />

                  <ul>
                    <li>Key Features: Integrate with Microsoft Entra for secure identity and access management.</li>
                    <li>Enable automated start/stop schedules to optimize cloud usage costs.</li>
                    <li>Use tagging automation to separate projects, environments (Dev, Test, Prod), and track resource usage.</li>
                  </ul>

                  <strong>Benefits:</strong> Standardized development environments, reduced infrastructure costs, and improved collaboration across remote and hybrid teams.
                </div>
              </div>
            </Card>
          </Col>

          <Col md={6} className="mb-4">
            <Card
              className="mwm-card"
              style={{
                backgroundImage: `url(${mwmImage3})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '500px'
              }}
            >
              <div className="blog-card spring-fever">
                <div className="title-content">
                  <h3>Secure Login and Access</h3>
                </div>
                <div className="card-info">
                  <strong>Use Case:</strong>Centralized Identity and Access Management<br></br>
                  <strong>•	Scenario:</strong> Organizations need to manage secure access to cloud resources, virtual desktops, and applications while enforcing compliance.<br></br>
                  <strong>•	Solution:</strong>Integrate with Microsoft Entra for centralized identity, role-based access control (RBAC), and MFA.
                  <ul>
                    <li>Enable conditional access policies to allow login only from trusted locations or devices.</li>
                    <li>Implement session timeout and automated logout for security.</li>
                    <li>Monitor user activity through built-in logs and compliance dashboards.</li>
                  </ul>
                  <strong>Benefits:</strong> Enhanced security, compliance, and centralized management for user authentication.
                  </div>
              </div>
            </Card>
          </Col>
          
          <Col md={6} className="mb-4">
            <Card
              className="mwm-card"
              style={{
                backgroundImage: `url(${mwmImage4})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '500px'
              }}
            >
              <div className="blog-card spring-fever">
                <div className="title-content">
                  <h3>Knowledge Workers</h3>
                </div>
                <div className="card-info">
                  <strong>Use Case:</strong>Seamless and Secure Remote Workspace Management<br></br>
                  <strong>•	Scenario:</strong> Knowledge workers (e.g., analysts, consultants) require easy access to virtual desktops, office tools, and secure file storage while working remotely or in hybrid setups.<br></br>
                  <strong>•	Solution:</strong> Deploy cloud workspaces integrated with tools like Microsoft 365, Teams, and project management platforms.
                  <ul>
                    <li>Enable secure remote access with multi-factor authentication (MFA) via Entra.</li>
                    <li>Automate resource scaling and scheduling to match worker demand.</li>
                    <li>Provide a user-friendly portal for workers to manage their virtual desktops without IT intervention.</li>
                  </ul>
                  <strong>Benefits:</strong> Increased productivity, secure collaboration, and reduced IT management overhead.
               </div>
               </div>
            </Card>
          </Col>
        </Row>

        {/* User Stories Section */}
        <Row className="mb-5 text-center">
          <Col>
            <h2 className="fw-bold">User Stories & Testimonials</h2>
            <p className="text-muted">
              Hear from our happy users about their experiences with Dhruthzuci.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
            <Card style={cardStyle}>
              <Card.Body>
                <Card.Title>End-User Experience</Card.Title>
                <Card.Text>
                  “With Dhruthzuci’s workspace solution, I can easily access my
                  work environment from home or on-site without relying on IT
                  support. It’s a game-changer for our team’s flexibility.” —
                  Alex, Project Manager at SmartSolutions Co.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-4">
            <Card style={cardStyle}>
              <Card.Body>
                <Card.Title>Business Perspective</Card.Title>
                <Card.Text>
                  “Switching to this solution allowed us to reduce operational
                  costs by 40% and remove the complexity of managing enterprise
                  licenses. Our team is now more agile and productive.” — Sarah,
                  Operations Head at GreenTech Corp.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Call-to-Action Section */}
        <Row className="mt-5 text-center">
          <Col>
            <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
              Empowering Businesses of All Sizes
            </h2>
            <p style={{ fontSize: "1rem", color: "#6c757d" }}>
              Scalable, cost-effective workspace management for startups and
              established companies alike.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className="shadow-sm border-0">
              <Card.Body className="text-center">
                <Card.Title style={{ fontWeight: "bold", color: "#007bff" }}>
                  Ready to Transform Your Workspace?
                </Card.Title>
                <Card.Text>
                  Submit your details to revolutionize how you manage your
                  workspaces.
                </Card.Text>
                {/* <Button variant="primary" size="lg" block>
                  <a
                    style={{ fontFamily: "impacted", color: "#fff" }}
                    href="/contactus"
                  >
                Get start
                  </a>
                </Button> */}
                 <Button
                    onClick={handleModalShow}
                                    variant="primary"
                                    size="lg"
                                    style={{ fontFamily: "impacted", color: "#fff" }}
                                  >
                                    Book a Meeting
                                  </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="shadow-sm border-0">
              <Card.Body className="text-center">
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
        
      <Modal show={showModal} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Book a Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="iframe-container"
            style={{ position: "relative", width: "100%", height: "500px" }}
          >
            <iframe
              src="https://outlook.office365.com/owa/calendar/MordernworkspaceManagement@dhruthzucitech.solutions/bookings/"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: "0", borderRadius: "5px" }}
              title="Meeting Booking"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      </Container>{" "}
    </div>
  );
};

export default MWMPage;