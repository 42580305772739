import React from "react";
import { BACKGROUNDCOLOR } from "../../../Themes/theme";
import { Button, Card, Container, Row, Col, Image } from "react-bootstrap";
import { EVENTS_BACKGROUND, EVENTS_CONFERENCE, EVENTS_ENTERTAINMENT, EVENTS_FITNESS, EVENTS_HIRING, EVENTS_MEETUPS, EVENTS_NEW, EVENTS_SFLYER, HACKATHON_WORKSHOP } from "../../../Components/Tabs/Events/config";
import { backgroundImage } from "../../../Assets";
import { Link } from "react-router-dom";
import EventsHero from "../../EventsHero/EventsHero";



const bannerStyle = {
  background: `url(${EVENTS_BACKGROUND})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",  // Set width to cover the entire screen
    height: "100vh", // Set height to cover the entire screen height
    marginTop: "10vh"
};


export default function Events() {
  const events = [
    { image: EVENTS_CONFERENCE, link: "/conference" },
    { image: EVENTS_FITNESS, alt: "Fitness", link: "/fitness" },
    { image: HACKATHON_WORKSHOP, alt: "Hackathon Workshop", link: "/hackathon" },
    { image: EVENTS_HIRING, alt: "Hiring", link: "/hiring" },
    { image: EVENTS_ENTERTAINMENT, alt: "Entertainment", link: "/entertainment" },
    { image: EVENTS_MEETUPS, alt: "Meetups", link: "/meetups" },
  ];
  return (
    <div style={{ marginTop: "0" }}>
<EventsHero/>
      <Row>
        <Col md={9} style={{ marginTop: "200px", marginBottom: "200px", marginLeft: "0", marginRight: "0" }}>
          <h1
            style={{
              fontFamily: "impacted",
              fontSize: "40px",
              marginBottom: "50px",
              marginLeft: "200px", // Adjust the left margin as needed
            }}
          >
            <u>Events Details</u>
          </h1>
          <Row style={{ margin: "0" }}>
            {events.map((event, index) => (
              <Col key={index} style={{ marginBottom: "20px", padding: "0" }}>
                {/* Use React Router's Link to wrap the image and provide the redirection link */}
                <Link to={event.link}>
                  <img
                    src={event.image}
                    alt={event.alt}
                    style={{ width: "100%", cursor: "pointer"}}
                  />
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={3} style={{ backgroundColor: "#f2f2f2", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}>
          <img
src={EVENTS_NEW}
alt="new event"
style={{ width: "40%", display: "block", margin: "auto", marginTop:"50px", marginBottom:"20px"}}
/>
  <Card style={{ width: '18rem', margin: 'auto' }} className="mx-auto">
    <Card.Body >
      <Card.Title><u>This Month's Events</u></Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        <ul>
          <li>Event 1</li>
        </ul>
      </Card.Subtitle>
      <Card.Img variant="top" src={EVENTS_SFLYER} />

      <Card.Link style={{ color: 'darkorange', textAlign: 'right', display: 'block', marginTop: '20px' }} href="/fitness-event">
        View more details
      </Card.Link>
    </Card.Body>
  </Card>
</Col>

      </Row>
    </div>
  );
}