

import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";

const MWMbookingForm = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  return (
    <section
    
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="shadow-lg border-0">
              <Card.Header
                className="text-center"
                style={{
                  backgroundColor: "#0C1A4A",
                  color: "#FFFFFF",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                Schedule a Meeting with Us
              </Card.Header>
              <Card.Body>
                <Card.Text className="text-center mb-4">
                  Easily book a time to connect with us using the button below.
                  We look forward to discussing your needs!
                </Card.Text>
                <div className="text-center">
                  <Button
                    onClick={handleModalShow}
                    variant="dark"
                    size="lg"
                    style={{
                      backgroundColor: "#0C1A4A",
                      borderColor: "#0C1A4A",
                    }}
                  >
                    Book a Meeting
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Book a Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="iframe-container"
            style={{ position: "relative", width: "100%", height: "500px" }}
          >
            <iframe
              src="https://outlook.office365.com/owa/calendar/MordernworkspaceManagement@dhruthzucitech.solutions/bookings/"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: "0", borderRadius: "5px" }}
              title="Meeting Booking"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default MWMbookingForm;
